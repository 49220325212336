import React, { useState } from 'react';
import { StepRouterComponent } from 'modules/app/components/stepMechanism';
import { FormattedMessage } from 'react-intl';
import { AppState } from 'modules/redux-store';
import { useSelector } from 'react-redux';

import * as styles from '../styles/Steps.styles';

import {
  AccountQuery,
  ContentWithDeco,
  Heading,
  SocialLogin,
} from 'modules/app';
import { LoginForm, LoginFormData } from '../forms/LoginForm';
import { FADE_IN, FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';
import { CookieForm } from 'modules/register/steps/CookieForm';
import { useAuth } from 'modules/auth';

interface Props extends StepRouterComponent {
  isCookieAccepted: boolean;
  setIsCookieAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StepOne: React.FC<Props> = ({
  isCookieAccepted,
  setIsCookieAccepted,
}) => {
  const { error, loginUser } = useAuth();
  const login = useSelector((state: AppState) => state.login);

  const [isInRequest, setIsInRequest] = useState(false);

  const onValid = async (data: LoginFormData) => {
    setIsInRequest(true);
    await loginUser(data);
    setIsInRequest(false);
  };

  if (!isCookieAccepted) {
    return (
      <ContentWithDeco css={styles.root}>
        <div css={styles.accountLink}>
          <AccountQuery type="login" />
        </div>

        <CookieForm setIsCookieAccepted={setIsCookieAccepted} />
      </ContentWithDeco>
    );
  }

  return (
    <ContentWithDeco
      css={styles.root}
      footer={
        <motion.div {...FADE_IN} transition={{ delay: 0.3, duration: 0.5 }}>
          <SocialLogin text="shared.social.social_component_login" />
        </motion.div>
      }
    >
      <div css={styles.accountLink}>
        <AccountQuery type="login" />
      </div>

      <motion.div
        transition={{ delay: 0.1, duration: 0.4 }}
        style={{ opacity: 0 }}
        {...FADE_IN_MOVE_Y_REGULAR}
      >
        <Heading css={styles.title} as="h1" size="fluidXxLarge">
          <FormattedMessage id="login.page.login_title" />
        </Heading>

        <LoginForm
          loginData={{
            email: login.email,
            password: login.password,
            remember: login.remember,
            token: '',
          }}
          error={error}
          isInRequest={isInRequest}
          onValid={onValid}
        />
      </motion.div>
    </ContentWithDeco>
  );
};

import React, { useState } from 'react';
import { StepRouterComponent } from 'modules/app/components/stepMechanism';
import { FormattedMessage } from 'react-intl';
import { AppState } from 'modules/redux-store';
import { useSelector } from 'react-redux';

import * as styles from '../styles/Steps.styles';

import {
  AccountQuery,
  BaseCookieConsent,
  ContentWithDeco,
  CookieConsent,
  Heading,
  SocialLogin,
  useCookiePolicy,
} from 'modules/app';
import { LoginForm, LoginFormData } from '../forms/LoginForm';
import { FADE_IN, FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';
import { CookieForm } from 'modules/register/steps/CookieForm';
import { useAuth } from 'modules/auth';
import { useLocation } from '@reach/router';

export const StepOneToken: React.FC<StepRouterComponent> = ({
  companyInviteToken,
  companyClaimToken,
}) => {
  const { error, loginUser } = useAuth();
  const { getCookie } = useCookiePolicy();
  const { search } = useLocation();
  const login = useSelector((state: AppState) => state.login);

  const [isInRequest, setIsInRequest] = useState(false);
  const [isCookieAccepted, setIsCookieAccepted] = useState(
    getCookie() === 'accept',
  );

  const onValid = async (data: LoginFormData) => {
    setIsInRequest(true);
    await loginUser(data, companyInviteToken, `${companyClaimToken}${search}`);
    setIsInRequest(false);
  };

  return (
    <ContentWithDeco
      css={styles.root}
      footer={
        isCookieAccepted && (
          <motion.div {...FADE_IN} transition={{ delay: 0.3, duration: 0.5 }}>
            <SocialLogin text="shared.social.social_component_login" />
          </motion.div>
        )
      }
    >
      <BaseCookieConsent>
        <CookieConsent onAction={setIsCookieAccepted} />
      </BaseCookieConsent>

      <div css={styles.accountLink}>
        <AccountQuery
          type="login"
          companyInviteToken={companyInviteToken}
          companyClaimToken={companyClaimToken}
        />
      </div>

      {isCookieAccepted ? (
        <motion.div
          transition={{ delay: 0.1, duration: 0.4 }}
          style={{ opacity: 0 }}
          {...FADE_IN_MOVE_Y_REGULAR}
        >
          <Heading css={styles.title} as="h1" size="fluidXxLarge">
            <FormattedMessage id="login.page.login_title" />
          </Heading>

          <LoginForm
            loginData={{
              email: login.email,
              password: login.password,
              remember: login.remember,
              token: '',
            }}
            error={error}
            isInRequest={isInRequest}
            onValid={onValid}
          />
        </motion.div>
      ) : (
        <CookieForm setIsCookieAccepted={setIsCookieAccepted} />
      )}
    </ContentWithDeco>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import {
  StepRouterComponent,
  BackBtn,
} from 'modules/app/components/stepMechanism';
import { FormattedMessage } from 'react-intl';
import { Link, navigate } from 'gatsby-plugin-intl';
import { HttpError } from 'modules/app/http';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

import { AuthForm } from '../forms/AuthForm';
import { ContentWithDeco, Heading, Paragraph } from 'modules/app';
import { useAuth } from 'modules/auth';

import * as styles from '../styles/Steps.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { useLocation } from '@reach/router';

export const StepTwo: React.FC<StepRouterComponent> = ({ onChangeStep }) => {
  const { search } = useLocation();
  const { error, loginUser, getInviteToken } = useAuth();
  const { inviteToken, claimToken } = useMemo(
    () => getInviteToken(search),
    [search],
  );

  const [isInRequest, setIsInRequest] = useState(false);
  const login = useSelector((state: AppState) => state.login);

  const onBack = () => {
    onChangeStep('step-one');
    navigate('/login');
  };

  useEffect(() => {
    onChangeStep('step-two');
  }, [onChangeStep]);

  useEffect(() => {
    if (!login.is2faAuth) {
      navigate('/login');
    }
  }, [login]);

  const onValid = async (token: Record<'token', string>) => {
    setIsInRequest(true);
    await loginUser(
      {
        email: login.email,
        password: login.password,
        remember: login.remember,
        token: token.token,
      },
      inviteToken,
      claimToken,
    );
    setIsInRequest(false);
  };

  return (
    <ContentWithDeco css={[styles.root, styles.negative]}>
      <div css={styles.accountLink}>
        <BackBtn onBack={onBack} negative />
      </div>

      <motion.div
        transition={{ delay: 0.1, duration: 0.4 }}
        style={{ opacity: 0 }}
        {...FADE_IN_MOVE_Y_REGULAR}
      >
        <Heading css={styles.title} as="h1" size="xxLarge">
          <FormattedMessage id="login.page.two_factor_auth_title" />
        </Heading>

        <Heading css={styles.subtitle} as="h2" size="base">
          <FormattedMessage id="login.page.two_factor_auth_subtitle" />
        </Heading>

        <div css={styles.formGroup}>
          <AuthForm onValid={onValid} isInRequest={isInRequest} />
        </div>

        <div css={styles.lostDeviceLink} role="presentation">
          <Paragraph size="tiny" fontFamily="primary">
            <FormattedMessage
              id="login.page.lost_device_link"
              values={{
                lost_device_link_location: (
                  <Link to="/support#support-contact-form">
                    <FormattedMessage id="login.page.lost_device_link_location" />
                  </Link>
                ),
              }}
            />
          </Paragraph>
        </div>

        <HttpError error={error} />
      </motion.div>
    </ContentWithDeco>
  );
};

import React from 'react';
import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Seo from 'modules/app/components/Seo';
import { PublicRouter } from 'modules/app';
import { Root as LoginRoot } from '../modules/login/Root';

import { TokenRoot as TokenLoginRoot } from 'modules/login/TokenRoot';

const Login: React.FC<PageProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.login' })} />

      <Router basepath="/:lang/login">
        <PublicRouter
          path={`invite/:companyInviteToken/*`}
          Component={TokenLoginRoot}
        />
        <PublicRouter
          path="claim/:companyClaimToken/*"
          Component={TokenLoginRoot}
        />

        <PublicRouter path={`/*`} Component={LoginRoot} />
      </Router>
    </>
  );
};

export default Login;

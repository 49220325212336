import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

import * as buttonStyles from 'modules/app/styles/Button.styles';

export const button = css`
  ${buttonStyles.secondary};
  ${buttonStyles.mediumExtended};
`;

export const input = css`
  margin-bottom: ${spacing(32)};
`;

export const buttonGroup = css`
  margin-top: calc(var(--unit) * 15);
`;

export const errorContainer = css`
  display: flex;
  justify-content: space-between;

  & > div:first-of-type {
    flex: 1;
    margin-right: ${spacing(32)};
  }
`;

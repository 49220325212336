import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  ButtonGroup,
  CheckboxField,
  EmailField,
} from 'modules/app/components';
import { emailRegex, HttpError, Counter } from 'modules/app';

import * as styles from 'modules/login/styles/LoginForm.styles';
import { HighlightedLink } from 'modules/app/components/shared/auth/HighlightedLink';
import { PasswordField } from 'modules/app/components/form/PasswordField';
import { FormattedMessage } from 'react-intl';
import { useVerificationEmailResend } from 'modules/register/hooks';
import { HttpError as IHttpError } from 'modules/app/http/client/httpError';

export interface LoginFormData {
  email: string;
  password: string;
  remember: boolean;
  token: string;
}

interface Props {
  loginData: LoginFormData;
  onValid: (data: LoginFormData) => void;
  isInRequest: boolean;
  error?: IHttpError;
}

export const LoginForm: React.FC<Props> = ({
  onValid,
  isInRequest,
  error,
  loginData,
}) => {
  const { onResendEmail } = useVerificationEmailResend();
  const [isDisabled, setIsDisabled] = useState(false);

  const methods = useForm<LoginFormData>({
    defaultValues: {
      email: loginData.email,
      password: loginData.password,
      remember: loginData.remember,
    },
  });
  const { handleSubmit, getValues } = methods;

  const handleEmailResend = () => {
    if (isDisabled) return;

    onResendEmail(getValues('email'));
    setIsDisabled(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onValid)}>
        <EmailField
          value={loginData.email}
          constraints={{
            required: {
              value: true,
              message: 'register.errors.email_required',
            },
            pattern: {
              value: emailRegex,
              message: 'register.errors.email_invalid',
            },
          }}
        />

        <PasswordField
          enablePwdStrength={false}
          value={loginData.password}
          label="login.page.fields.password"
        />

        <div css={styles.input}>
          <CheckboxField
            name="remember"
            checked={loginData.remember}
            constraints={{}}
          >
            <FormattedMessage id="login.rememberMeLabel" />
          </CheckboxField>
        </div>

        <div css={styles.errorContainer}>
          <HttpError error={error} />

          {error?.cause === 'email_not_verified' && (
            <Button
              buttonType="primary"
              size="small"
              disabled={isDisabled}
              onClick={handleEmailResend}
              isLoading={isInRequest}
              type="button"
            >
              {isDisabled ? (
                <FormattedMessage
                  id="shared.verification.resend_label_timeout"
                  values={{
                    placeholder: (
                      <Counter
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                        duration={60}
                      />
                    ),
                  }}
                />
              ) : (
                <FormattedMessage id="shared.verification.resend_label" />
              )}
            </Button>
          )}
        </div>

        <ButtonGroup css={styles.buttonGroup}>
          <Button
            css={styles.button}
            textId="login.page.login_title"
            type="submit"
            disabled={isInRequest}
          />

          <div>
            <HighlightedLink
              to={'/app/forgot-password'}
              label="login.page.forgot_password"
            />
          </div>
        </ButtonGroup>
      </form>
    </FormProvider>
  );
};

import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NumberField } from 'modules/app/components';
import * as styles from '../styles/2FAForm.styles';

interface Props {
  onValid: (token: Record<string, string>) => void;
  isInRequest: boolean;
  error?: ApiErrorData;
}

export const AuthForm: React.FC<Props> = ({ onValid, isInRequest }) => {
  const methods = useForm<Record<string, string>>({
    defaultValues: {
      token: '',
    },
  });

  const { handleSubmit } = methods;

  const onConditionMet = async (val: string) => {
    onValid({ token: val });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onValid)}>
        <NumberField
          css={styles.negative}
          name="code"
          onValid={onConditionMet}
          value={''}
          disabled={isInRequest}
          label="login.page.qr_code_label"
          constraints={{
            required: {
              value: true,
              message: 'register.errors.invalid_qr_code',
            },
            maxLength: {
              value: 6,
              message: 'register.errors.invalid_qr_code',
            },
            minLength: {
              value: 6,
              message: 'register.errors.invalid_qr_code',
            },
          }}
        />
      </form>
    </FormProvider>
  );
};
